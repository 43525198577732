<template>
  <section>
    <b-card>
      <b-card-body>
        <b-card-title> Números Ativos </b-card-title>

        <b-row
          v-for="(item, index) in items"
          :key="index"
        >
          <b-col
            cols="12"
            class="mt-2 d-flex justify-content-center"
          >
            <img
              class="rounded-lg"
              :src="require(`@/assets/images/services/${item.image}`)"
              width="45px"
            >
          </b-col>

          <b-col
            cols="12"
            class="mt-2 d-flex justify-content-center"
          >
            <b-button
              v-clipboard:copy="item.number"
              v-clipboard:success="onCopy"
              size="md"
              variant="relief-primary"
            >
              {{ item.number }}
            </b-button>
          </b-col>

          <b-col
            cols="12"
            class="mt-2 d-flex justify-content-center"
          >
            <b-button
              v-if="item.sms === null"
              size="md"
              variant="relief-primary"
            >
              <span v-if="item.sms === null">Aguardando SMS...</span>
            </b-button>
            <b-button
              v-else
              v-clipboard:copy="item.sms"
              v-clipboard:success="onCopy"
              size="md"
              variant="relief-primary"
            >
              <span>{{ item.sms }}</span>
            </b-button>
          </b-col>

          <b-col
            cols="12"
            class="mt-2 d-flex justify-content-center"
          >
            <b-button :variant="item.timer_color">
              {{ item.timer }}
            </b-button>
          </b-col>

          <b-col
            cols="12"
            class="mt-2 d-flex justify-content-center"
          >
            <b-button
              v-if="item.sms !== null || item.timer === 'Tempo esgotado'"
              class="mr-1"
              :disabled="loading"
              size="sm"
              variant="relief-success"
              @click.stop="confirm(item.id)"
            >
              <feather-icon
                v-if="!loading"
                icon="CheckCircleIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>

            <b-button
              v-if="
                item.option === 'Opção 3' &&
                  item.timer !== 'Tempo esgotado' &&
                  item.sms !== null
              "
              :disabled="loading"
              class="mr-1"
              size="sm"
              variant="relief-warning"
              @click.stop="resend(item.id)"
            >
              <feather-icon
                v-if="!loading"
                icon="RefreshCcwIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>

            <b-button
              v-if="item.timer !== 'Tempo esgotado' && item.sms === null"
              :disabled="loading"
              size="sm"
              variant="relief-danger"
              @click.stop="dialogCancel(item.id)"
            >
              <feather-icon
                v-if="!loading"
                icon="Trash2Icon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>
          </b-col>

          <b-col
            v-if="items.length > 1 && index !== items.length - 1"
            cols="12"
          >
            <hr>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BButton,
  BSpinner
} from 'bootstrap-vue'

import TimerMixin from './TimerMixin'
import AvailableNumbersMixin from './AvailableNumbersMixin'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    BSpinner
  },
  mixins: [TimerMixin, AvailableNumbersMixin]
}
</script>
