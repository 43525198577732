import moment from 'moment'
import 'moment-duration-format'

export default {
  data: () => ({
    intervals: []
  }),
  methods: {
    updateTimer () {
      for (const item of this.items) {
        this.intervals[item.id] = setInterval(() => {
          const time = moment(item.end_time).diff()

          if (time < 0) {
            item.timer = 'Tempo esgotado'
            item.timer_color = 'danger'
            clearInterval(this.intervals[item.id])
            return
          }

          item.timer = moment.duration(time).format('mm:ss', { trim: false })

          if (time > (300 * 1000)) {
            item.timer_color = 'success'
          }

          if (time <= (300 * 1000)) {
            item.timer_color = 'warning'
          }

          if (time <= (120 * 1000)) {
            item.timer_color = 'danger'
          }
        })
      }
    }
  }
}
