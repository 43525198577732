<template>
  <section v-if="show">
    <b-card>
      <b-card-body>
        <b-card-title> Números Ativos </b-card-title>
      </b-card-body>
      <b-table
        striped
        :fields="fields"
        :items="items"
        responsive
      >
        <template #cell(actions)="data">
          <b-button
            v-if="
              data.item.sms !== null || data.item.timer === 'Tempo esgotado'
            "
            class="mr-1"
            :disabled="loading"
            size="sm"
            variant="relief-success"
            @click.stop="confirm(data.item.id)"
          >
            <feather-icon
              v-if="!loading"
              icon="CheckCircleIcon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            v-if="
              data.item.option === 'Opção 3' &&
                data.item.timer !== 'Tempo esgotado' &&
                data.item.sms !== null
            "
            :disabled="loading"
            class="mr-1"
            size="sm"
            variant="relief-warning"
            @click.stop="resend(data.item.id)"
          >
            <feather-icon
              v-if="!loading"
              icon="RefreshCcwIcon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            v-if="data.item.timer !== 'Tempo esgotado' && data.item.sms === null"
            :disabled="loading"
            size="sm"
            variant="relief-danger"
            @click.stop="dialogCancel(data.item.id)"
          >
            <feather-icon
              v-if="!loading"
              icon="Trash2Icon"
            />
            <b-spinner
              v-else
              small
            />
          </b-button>
        </template>

        <template #cell(number)="data">
          <b-button
            v-clipboard:copy="data.item.number"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            {{ data.item.number }}
          </b-button>
        </template>

        <template #cell(last_sms)="data">
          <b-button
            v-if="data.item.sms === null"
            size="sm"
            variant="relief-primary"
          >
            <span v-if="data.item.sms === null">Aguardando SMS...</span>
          </b-button>
          <b-button
            v-else
            v-clipboard:copy="data.item.sms"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            <span>{{ data.item.sms }}</span>
          </b-button>
        </template>

        <template #cell(product)="data">
          <img
            class="rounded-lg"
            :src="`${$api_host}/storage/assets/${data.item.image}`"
            width="35px"
          >
        </template>

        <template #cell(time)="data">
          <b-button :variant="data.item.timer_color">
            {{ data.item.timer }}
          </b-button>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import TimerMixin from './TimerMixin'
import AvailableNumbersMixin from './AvailableNumbersMixin'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BButton,
    BSpinner
  },
  mixins: [TimerMixin, AvailableNumbersMixin],
  data: () => ({
    fields: [
      {
        key: 'actions',
        label: 'Ações'
      },
      {
        key: 'number',
        label: 'Número'
      },
      {
        key: 'last_sms',
        label: 'Último SMS'
      },
      {
        key: 'product',
        label: 'Produto'
      },
      {
        key: 'time',
        label: 'Tempo'
      }
    ]
  })
}
</script>
