import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data: () => ({
    show: false,
    items: [],
    loading: false
  }),
  methods: {
    async getAvailableNumbers () {
      await this.$http
        .get('/user/services/my_purchases/available_numbers')
        .then(res => {
          this.items = res.data.map(item => {
            return {
              ...item,
              timer: '00:00',
              timer_color: ''
            }
          })
        })
        .finally(() => {
          this.show = true
          this.updateTimer()
          this.listenSMS()
        })
    },
    confirmDialog () {
      this.$swal({
        title: 'Saldo insuficiente!',
        text: 'Você não tem o saldo necessário para fazer essa compra!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Adicionar Saldo',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-primary ml-1'
        },
        buttonsStyling: false
      }).then(buttons => {
        if (buttons.isConfirmed) {
          this.$router.push({ name: 'Adicionar Saldo' })
        }
      })
    },
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    async confirm (id) {
      this.loading = true
      await this.$http.get(`/user/services/sms/confirm/${id}`)
        .then(() => {
          this.items = this.items.filter(item => item.id !== id)

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Movido para o histórico!',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'O número foi enviado para o seu histórico de sms.'
            }
          })
        }).finally(() => {
          this.loading = false
        })
    },
    async resend (id) {
      this.loading = true
      await this.$http.get(`/user/services/sms/resend/${id}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'O SMS foi reenviado.',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Aguarde até o novo sms chegar!'
            }
          })
        }).finally(() => {
          this.loading = false
        })
    },
    dialogCancel (id) {
      this.$swal({
        title: 'Você tem certeza que deseja cancelar esse sms?',
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.cancel(id)
        }
      })
    },
    async cancel (id) {
      this.loading = true
      await this.$http.get(`/user/services/sms/cancel/${id}`)
        .then(res => {
          if (res.data.message === 'Is not possible to cancel this SMS.') {
            this.$swal({
              title: 'Ops! Algo de errado não está certo!',
              html: 'Não é possível cancelar esse sms! <br> <br> <b>Possíveis motivos:</b> <br> <br> 1. Você já recebeu o SMS. <br> <br> 2. Esse sms já foi cancelado.<br> <br> 3. O tempo limite esgotou.<br> <br> 4.Por um erro interno no sistema.</li> </ul>',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            return
          }

          this.items = this.items.filter(item => item.id !== id)

          this.$swal({
            icon: 'success',
            title: 'Cancelado!',
            text: 'O número foi cancelado e enviado para o seu histórico de sms.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        }).finally(() => {
          this.loading = false
        })
    },
    listenSMS () {
      setTimeout(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        window.Echo.private(`user.${userData.id}`).listen(
          '.SMSCode',
          res => this.resolveSMS(res)
        )
      }, 2000)
    },
    resolveSMS (res) {
      for (const item of this.items) {
        if (item.id === res.id) {
          item.sms = res.sms
        }
      }
    }
  },
  mounted () {
    this.getAvailableNumbers()
  }
}
