<template>
  <section>
    <component :is="screen.width >= 900 ? 'PC' : 'Mobile'" />
  </section>
</template>

<script>
import PC from './PC.vue'
import Mobile from './Mobile.vue'

export default {
  components: {
    PC,
    Mobile
  },
  computed: {
    screen () {
      return {
        width: window.screen.width,
        height: window.screen.height
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
